@import "../../../style/App.scss";

.button {
  display: flex;
  padding-bottom: "1rem";
  justify-content: center;
  .button-link {
    background-color: $main-color;
    border: 2px solid $main-color;
    padding: 15px 30px;
    cursor: pointer;
    font-size: 1.4rem;
    font-weight: 800;
    color: $white-color;
    border-radius: 4px;
  }
  .button-link:hover {
    border: 2px solid $main-color;
    background-color: transparent;
    color: $main-color;
  }
}
