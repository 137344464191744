@import "../../style/App.scss";

.hero {
  padding-top: 240px;
  width: 100%;
  padding-bottom: 60px;
  background: #f0f2f5;
  .hero-info {
    padding-top: 60px;

    p {
      padding-top: 20px;
      padding-bottom: 20px;
    }
  }
  .hero-buttons {
    display: flex;
    margin-bottom: 2rem;
    :first-child {
      margin-right: 8px;
    }
    @media all and (max-width: 486px) {
      flex-direction: column;
      :first-child {
        margin-bottom: 8px;
      }
    }
  }
}

@media screen and (max-width: 1080px) {
  .hero {
    padding-top: 140px;

    .hero-info {
      padding-top: 0px;
    }

    .hero-image {
      float: none;
      margin: 0 auto;
      margin-top: 50px;
    }
  }
}
.Typewriter__cursor {
  color: #fff;
}
@media screen and (max-width: 990px) {
  .hero {
    .hero-info {
      text-align: center;
    }
    .hero-buttons {
      justify-content: center;
    }
  }
}
