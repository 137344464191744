@import "../../style/App.scss";

#portfolio {
  padding: 20px 0 40px 0;
  background-image: linear-gradient(to top, #cfd9df 0%, #e2ebf0 100%);
  border-top: 1px solid #d1d9de;
  border-bottom: 1px solid #d1d9de;
  .portfolio__nav {
    ul {
      display: flex;
      @media all and (max-width: 860px) {
        flex-direction: column;
      }
      li {
        padding: 5px 15px;
        margin-right: 10px;
        cursor: pointer;
        transition: 0.3s ease;
      }
      li:hover {
        background-color: $main-color;
      }
    }
    .portfolio__nav-active {
      background-color: $main-color;
      color: white;
    }
  }
  .portfolio__filter {
    display: flex;
    justify-content: flex-end;
    cursor: pointer;
    margin-bottom: 50px;
    background-attachment: fixed;
    max-width: 200px;
    float: right;
    position: relative;
    padding: 8px 10px;

    p {
      padding-right: 10px;
    }

    .portfolio__filter-menu {
      background-color: $light-color;
      position: absolute;
      top: 40px;
      left: 0;
      width: 100%;
      height: 100px;
      text-align: center;
      border-radius: 5px;
      z-index: 999;
      p {
        padding: 8px 0;
        margin: 2px 0;
        transition: 0.3s ease;
      }
      p:hover {
        background-color: $main-color;
      }
    }
  }
}

.my-masonry-grid {
  display: -webkit-box; /* Not needed if autoprefixing */
  display: -ms-flexbox; /* Not needed if autoprefixing */
  display: flex;
  margin-left: -30px; /* gutter size offset */
  width: auto;
}
.mint__gallery {
  padding-left: 30px; /* gutter size */
  background-clip: padding-box;
}

/* Style your items */
.mint__gallery > div {
  /* change div to reference your elements you put in <Masonry> */
  margin-bottom: 30px;
}

@media screen and (max-width: 767px) {
  #portfolio {
    .portfolio__nav {
      ul {
        justify-content: center;
      }
    }
    .portfolio__filter {
      justify-content: center;
      max-width: 200px;
      margin: 30px auto;
      float: none;
    }
  }
}

@media screen and (max-width: 400px) {
  #portfolio {
    .portfolio__nav {
      ul {
        li {
          padding: 5px 5px;
          margin-right: 5px;
        }
      }
    }
  }
}
