@import "../../../style/App.scss";

.portfolio__box {
  position: relative;
  max-width: 370px;
  margin: 15px auto;
  cursor: pointer;
  background: white;
  box-shadow: 0px 3px 1px -2px rgba(0, 0, 0, 0.2),
    0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 1px 5px 0px rgba(0, 0, 0, 0.12);
  padding: 16px;
  img {
    width: 100%;
    height: auto;
  }

  .portfolio__hover-info {
    background-color: rgba(#edf2f6, 0.8);
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    opacity: 0;
    transition: 0.3s ease;
    padding: 16px;
    overflow: auto;

    p {
      text-transform: uppercase;
      color: black;
    }
    .stack {
      color: rgba(0, 0, 0, 0.54);
    }
  }
  .portfolio__hover-info:hover {
    opacity: 1;
  }
}
