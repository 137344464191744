@import "../../style/App.scss";

.team__info {
  margin: 0 auto;
  margin-top: 60px;
  position: relative;
  border: 4px solid $light-color;
  @media all and (max-width: 768px) {
    padding: 1rem;
  }
  padding: 50px;
  margin-bottom: 60px;

  h4 {
    padding-bottom: 20px;
  }
  p {
    padding-bottom: 20px;
  }
}
