.hero-editor {
  position: relative;
  width: 100%;
  height: 100%;
  min-height: 300px;
  float: right;
  background: #353b46;
  border-radius: 0.5rem;
  overflow: hidden;
  box-shadow: 1px 5px 10px rgba(0, 0, 0, 0.2);
  @media all and (max-width: 768px) {
    font-size: 1.25rem;
  }
  .editor-topbar {
    background: #eef1f5;
    height: 37px;
    position: relative;
    .editor-action-buttons {
      position: absolute;
      top: 50%;
      left: 10px;
      margin-top: -5px;
      width: 10px;
      height: 10px;
      background: Crimson;
      border-radius: 50%;
      box-shadow: 15px 0 0 orange, 30px 0 0 limegreen;
    }
  }
  .editor-content {
    padding: 1rem 0;
  }
  .editor-first-column {
    padding-left: 16px;
  }
  .editor-comment {
    color: #7f848e;
    font-style: italic;
  }
  .editor-keyword {
    color: #c678dd;
  }
  .editor-functions {
    color: #61afef;
  }
  .editor-brackets {
    color: #d4d4d4;
  }
  .editor-variables {
    color: #e06c75;
  }
  .editor-strings {
    color: #98c379;
  }
  .editor-constants {
    color: #e5c07b;
  }
  .editor-special {
    color: #56b6c2;
  }
  .editor-column-element {
    color: #7f848e;
    display: flex;
    justify-content: center;
  }
}
